import { Interface } from '@ethersproject/abi'
import { BigNumber } from '@ethersproject/bignumber'
import { hexStripZeros } from '@ethersproject/bytes'
import { Contract, ContractReceipt } from '@ethersproject/contracts'
import type { JsonRpcSigner } from '@ethersproject/providers'
import { Seaport } from '@opensea/seaport-js'
import { NFTEventName } from '@uniswap/analytics-events'
import MINTABLE_ABI from 'abis/mintable.json'
import { sendAnalyticsEvent } from 'analytics'
import { SEAPORT_CONDUIT_KEY, SEAPORT_KEY_TO_CONDUIT, SEAPORT_V15_CONTRACTS } from 'constants/electroSwapContracts'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import ERC721 from '../../abis/erc721.json'
import ERC1155 from '../../abis/erc1155.json'
import { GenieAsset, RouteResponse, RoutingItem, TxResponse, TxStateType, UpdatedGenieAsset } from '../types'
import { compareAssetsWithTransactionRoute } from '../utils/txRoute/combineItemsWithTxRoute'

interface TxState {
  state: TxStateType
  setState: (state: TxStateType) => void
  txHash: string
  clearTxHash: () => void
  purchasedWithErc20: boolean
  sendTransaction: (
    signer: JsonRpcSigner,
    selectedAssets: UpdatedGenieAsset[],
    assetsToMint: UpdatedGenieAsset[],
    purchasedWithErc20: boolean,
    transactionData?: RouteResponse
  ) => Promise<TxResponse | undefined>
}

export const useSendTransaction = create<TxState>()(
  devtools(
    (set) => ({
      state: TxStateType.New,
      txHash: '',
      purchasedWithErc20: false,
      clearTxHash: () => set({ txHash: '' }),
      setState: (newState) => set(() => ({ state: newState })),
      sendTransaction: async (signer, selectedAssets, assetsToMint, purchasedWithErc20, routingData) => {
        const minting = assetsToMint.length > 0
        const purchasing = selectedAssets.length > 0
        const nftsPurchased = []
        const nftsNotPurchased = []
        if (minting) {
          try {
            const mintableContract = new Contract(assetsToMint[0].address, MINTABLE_ABI, signer)
            const mintPrice = BigNumber.from(assetsToMint[0].priceInfo.ETNPrice)

            set({ state: TxStateType.Signing })

            const mintTx = await mintableContract.mint(assetsToMint.length, {
              value: mintPrice.mul(BigNumber.from(assetsToMint.length)),
            })

            set({ state: TxStateType.Confirming })
            set({ txHash: mintTx.hash })
            set({ purchasedWithErc20 })
            sendAnalyticsEvent(NFTEventName.NFT_BUY_BAG_SIGNED, { transaction_hash: mintTx.hash })

            const txReceipt = await mintTx.wait()

            //tx was mined successfully
            if (txReceipt.status === 1) {
              nftsPurchased.push(...(await findNFTsMinted(txReceipt, signer, assetsToMint)))
              if (!purchasing) {
                set({ state: TxStateType.Success })
                return {
                  nftsPurchased,
                  nftsNotPurchased: [],
                  txReceipt,
                }
              }
            } else if (!purchasing) {
              set({ state: TxStateType.Failed })
              return {
                nftsPurchased: [],
                nftsNotPurchased: assetsToMint,
                txReceipt,
              }
            }
          } catch (e) {
            console.log('Error creating mint Transaction', e)
            if (e.code === 4001) {
              set({ state: TxStateType.Denied })
            } else {
              set({ state: TxStateType.Invalid })
            }
            return
          }
        }

        if (purchasing) {
          const address = await signer.getAddress()
          try {
            const chainId = await signer.getChainId()

            const seaport = new Seaport(signer, {
              conduitKeyToConduit: SEAPORT_KEY_TO_CONDUIT[chainId],
              balanceAndApprovalChecksOnOrderCreation: true,
              overrides: {
                contractAddress: SEAPORT_V15_CONTRACTS[chainId],
                defaultConduitKey: SEAPORT_CONDUIT_KEY[chainId],
              },
              seaportVersion: '1.5',
            })

            const orders = selectedAssets.map((item) => {
              return {
                order: {
                  parameters: item.sellorders?.[0].protocolParameters as any,
                  signature: item.sellorders?.[0].signature || '',
                },
              }
            })

            const { executeAllActions } = await seaport.fulfillOrders({
              fulfillOrderDetails: orders,
              accountAddress: address,
            })

            if (!minting) {
              set({ state: TxStateType.Signing })
            }

            const res = await executeAllActions()
            if (!minting) {
              set({ state: TxStateType.Confirming })
              set({ txHash: res.hash })
              set({ purchasedWithErc20 })
            }
            sendAnalyticsEvent(NFTEventName.NFT_BUY_BAG_SIGNED, { transaction_hash: res.hash })

            const txReceipt = await res.wait()

            //tx was mined successfully
            if (txReceipt.status === 1) {
              nftsPurchased.push(...findNFTsPurchased(txReceipt, address, selectedAssets, undefined))
              nftsNotPurchased.push(...findNFTsNotPurchased(selectedAssets, nftsPurchased))
              set({ state: TxStateType.Success })
              return {
                nftsPurchased,
                nftsNotPurchased,
                txReceipt,
              }
            } else {
              set({ state: TxStateType.Failed })
              return {
                nftsPurchased: [],
                nftsNotPurchased: selectedAssets,
                txReceipt,
              }
            }
          } catch (e) {
            console.log('Error creating multiAssetSwap Transaction', e)
            if (e.code === 4001) {
              set({ state: TxStateType.Denied })
            } else {
              set({ state: TxStateType.Invalid })
            }
            return
          }
        }
        return
      },
    }),
    { name: 'useSendTransactionState' }
  )
)

// Sends via universal router
// export const useSendTransaction = create<TxState>()(
//   devtools(
//     (set) => ({
//       state: TxStateType.New,
//       txHash: '',
//       purchasedWithErc20: false,
//       clearTxHash: () => set({ txHash: '' }),
//       setState: (newState) => set(() => ({ state: newState })),
//       sendTransaction: async (signer, selectedAssets, transactionData, purchasedWithErc20) => {
//         const address = await signer.getAddress()
//         try {
//           const txNoGasLimit = {
//             to: transactionData.to,
//             value: transactionData.valueToSend ? BigNumber.from(transactionData.valueToSend) : undefined,
//             data: transactionData.data,
//           }

//           const gasLimit = (await signer.estimateGas(txNoGasLimit)).mul(105).div(100)
//           // tx['gasLimit'] = gasLimit
//           const tx = { ...txNoGasLimit, gasLimit } // TODO test this works when firing off tx

//           set({ state: TxStateType.Signing })
//           const res = await signer.sendTransaction(tx)
//           set({ state: TxStateType.Confirming })
//           set({ txHash: res.hash })
//           set({ purchasedWithErc20 })
//           sendAnalyticsEvent(NFTEventName.NFT_BUY_BAG_SIGNED, { transaction_hash: res.hash })

//           const txReceipt = await res.wait()

//           //tx was mined successfully
//           if (txReceipt.status === 1) {
//             const nftsPurchased = findNFTsPurchased(txReceipt, address, selectedAssets, transactionData.route)
//             const nftsNotPurchased = findNFTsNotPurchased(selectedAssets, nftsPurchased)
//             set({ state: TxStateType.Success })
//             return {
//               nftsPurchased,
//               nftsNotPurchased,
//               txReceipt,
//             }
//           } else {
//             set({ state: TxStateType.Failed })
//             return {
//               nftsPurchased: [],
//               nftsNotPurchased: selectedAssets,
//               txReceipt,
//             }
//           }
//         } catch (e) {
//           console.log('Error creating multiAssetSwap Transaction', e)
//           if (e.code === 4001) {
//             set({ state: TxStateType.Denied })
//           } else {
//             set({ state: TxStateType.Invalid })
//           }
//           return
//         }
//       },
//     }),
//     { name: 'useSendTransactionState' }
//   )
// )

const findNFTsPurchased = (
  txReceipt: ContractReceipt,
  signerAddress: string,
  toBuy: GenieAsset[],
  txRoute?: RoutingItem[]
): UpdatedGenieAsset[] => {
  if (!txReceipt.logs) {
    return []
  }
  const erc721Interface = new Interface(ERC721)
  const erc1155Interface = new Interface(ERC1155)

  // Find successfully purchased NFTs (and assign to state nftsPurchased) by parsing events
  const transferErc721BuyEvents = txReceipt.logs.filter(
    (x) =>
      x.topics[0] === erc721Interface.getEventTopic('Transfer') &&
      hexStripZeros(x.topics[2]).toLowerCase() === signerAddress.toLowerCase()
  )

  const transferredErc721 = transferErc721BuyEvents.map((x) => ({
    address: x.address,
    tokenId: parseInt(x.topics[3]).toString(),
  }))
  const transferErc1155BuyEvents = txReceipt.logs.filter(
    (x) =>
      x.topics[0] === erc1155Interface.getEventTopic('TransferSingle') &&
      hexStripZeros(x.topics[3]).toLowerCase() === signerAddress.toLowerCase()
  )

  const transferredErc1155 = transferErc1155BuyEvents.map((x) => ({
    address: x.address,
    tokenId: erc1155Interface.parseLog(x).args[3].toString(),
  }))

  const allTransferred = [...transferredErc721, ...transferredErc1155]

  const transferredItems = toBuy.filter((assetToBuy) => {
    return allTransferred.some(
      (purchasedNft) =>
        assetToBuy.address.toLowerCase() === purchasedNft.address.toLowerCase() &&
        parseInt(assetToBuy.tokenId).toString() === purchasedNft.tokenId
    )
  })

  return compareAssetsWithTransactionRoute(transferredItems, txRoute).updatedAssets
}

const findNFTsNotPurchased = (toBuy: GenieAsset[], nftsPurchased: UpdatedGenieAsset[]) => {
  const nftsNotPurchased: Array<UpdatedGenieAsset> = []
  toBuy.forEach((selectedAsset) => {
    const purchasedNft = nftsPurchased.find(
      (x) => x.address.toLowerCase() === selectedAsset.address.toLowerCase() && x.tokenId === selectedAsset.tokenId
    )
    if (!purchasedNft) {
      nftsNotPurchased.push(selectedAsset)
    }
  })

  return nftsNotPurchased
}

const findNFTsMinted = async (
  txReceipt: ContractReceipt,
  signer: any,
  assetsToMint: UpdatedGenieAsset[]
): Promise<UpdatedGenieAsset[]> => {
  if (!txReceipt.logs) {
    return []
  }
  const erc721Interface = new Interface(ERC721)
  //const erc1155Interface = new Interface(ERC1155)

  const signerAddress = await signer.getAddress()

  // Find successfully purchased NFTs (and assign to state nftsPurchased) by parsing events
  const transferErc721BuyEvents = txReceipt.logs.filter(
    (x) =>
      x.topics[0] === erc721Interface.getEventTopic('Transfer') &&
      hexStripZeros(x.topics[2]).toLowerCase() === signerAddress.toLowerCase()
  )

  const transferredErc721 = transferErc721BuyEvents.map((x) => ({
    address: x.address,
    tokenId: parseInt(x.topics[3]).toString(),
  }))

  // const transferErc1155BuyEvents = txReceipt.logs.filter(
  //   (x) =>
  //     x.topics[0] === erc1155Interface.getEventTopic('TransferSingle') &&
  //     hexStripZeros(x.topics[3]).toLowerCase() === signerAddress.toLowerCase()
  // )

  // const transferredErc1155 = transferErc1155BuyEvents.map((x) => ({
  //   address: x.address,
  //   tokenId: erc1155Interface.parseLog(x).args[3].toString(),
  // }))

  const allTransferred = [...transferredErc721 /*, ...transferredErc1155*/]

  const mintedItems: UpdatedGenieAsset[] = []

  for (const mintedNft of allTransferred) {
    const index = allTransferred.indexOf(mintedNft)
    if (mintedNft.address.toLowerCase() === assetsToMint[index].address.toLowerCase()) {
      try {
        const nftContract = new Contract(mintedNft.address, ERC721, signer)

        const tokenURI = await nftContract.tokenURI(mintedNft.tokenId)
        const metadata = await fetch(tokenURI.replace('ipfs://', 'https://ipfs.io/ipfs/'), {
          headers: {
            'User-Agent':
              'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36',
          },
        }).then((res) => res.json())

        if (metadata.image) {
          assetsToMint[index].imageUrl = metadata.image.replace('ipfs://', 'https://ipfs.io/ipfs/')
        }

        if (metadata.name) {
          assetsToMint[index].name = metadata.name
        }

        assetsToMint[index].tokenId = mintedNft.tokenId
      } catch (err) {
        console.log('Error fetching metadata', err)
      }

      mintedItems.push(assetsToMint[index])
    }
  }

  return mintedItems
}

import { ApolloClient, InMemoryCache } from '@apollo/client'
import { Reference, relayStylePagination } from '@apollo/client/utilities'

export const apolloClient = new ApolloClient({
  connectToDevTools: true,
  uri: process.env.REACT_APP_ELECTROSWAP_GRAPHQL,
  headers: {
    'Content-Type': 'application/json',
    //'Origin': 'https://app.electroswap.io',
  },
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          nftAssetDetails: {
            //keyArgs: ['address', 'tokenId'],
            read(_, { args, toReference }): Reference | undefined {
              //console.log('args', args)
              return toReference({
                __typename: 'nftAssetDetails',
                address: args?.address,
                tokenId: args?.tokenId,
              })
            },
          },
          nftBalances: relayStylePagination(['ownerAddress', 'filter', 'first']),

          nftAssets: relayStylePagination(['address', 'filter', 'orderBy', 'asc']),
          nftActivity: relayStylePagination(['filter', 'first']),
          topCollections: relayStylePagination(['chains', 'listed', 'first', 'duration']),
          nftCollections: relayStylePagination(['filter']),

          // tell apollo client how to reference Token items in the cache after being fetched by queries that return Token[]
          token: {
            read(_, { args, toReference }): Reference | undefined {
              return toReference({
                __typename: 'Token',
                chain: args?.chain,
                address: args?.address,
              })
            },
          },
        },
      },
      // NftAssetEdge: {
      //   keyFields: ['cursor'],
      // },
      // NftCollection: {
      //   keyFields: ['id'],
      // },
      // NftCollectionMarket: {
      //   keyFields: ['id'], f
      // },
      // NftContract: {
      //   keyFields: ['id'],
      // },
      // NftProfile: {
      //   keyFields: ['id'],
      // },
      // NftAsset: {
      //   keyFields: ['id'],
      // },
      // NftOrder: {
      //   keyFields: ['id'],
      // },
      // NftActivity: {
      //   keyFields: ['id'],
      // },
      // NftFee: {
      //   keyFields: ['id'],
      // },
      // NftAssetRarity: {
      //   keyFields: ['id'],
      // },
      // NftAssetTrait: {
      //   keyFields: ['id'],
      // },
      Token: {
        // key by chain, address combination so that Token(chain, address) endpoint can read from cache
        /**
         * NOTE: In any query for `token` or `tokens`, you must include the `chain` and `address` fields
         * in order for result to normalize properly in the cache.
         */
        keyFields: ['chain', 'address'],
        fields: {
          address: {
            read(address: string | null): string | null {
              // backend endpoint sometimes returns checksummed, sometimes lowercased addresses
              // always use lowercased addresses in our app for consistency
              return address?.toLowerCase() ?? null
            },
          },
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
  },
})
